import React from "react";
import Layout from "../components/_App/layout";
import NavbarStyleThree from "../components/_App/NavbarStyleThree";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
// import AboutOne from "../assets/images/about-one.png";
import ReactMarkdown from "react-markdown";
import { graphql, StaticQuery } from "gatsby";
import SEO from '../components/_App/seo';
import { getImageURL } from "../format-date";
const GET_REFUND_POLICY_INFORMATION = graphql`
query GetRPInformation {
    strapiRefundPolicy {
      description
      seo_description
      seo_image {
        localFile {
            name
            extension
        }
      }
      seo_title
    }
  }
  
`
const About1 = () => (
    <StaticQuery query={GET_REFUND_POLICY_INFORMATION} render={data => (
        <Layout>
            <SEO title={data.strapiRefundPolicy.seo_title} description={data.strapiRefundPolicy.seo_description} image={getImageURL(data.strapiRefundPolicy.seo_image.localFile.name, data.strapiRefundPolicy.seo_image.localFile.extension)} type="website" url="/refund-policy/" />
            <NavbarStyleThree />
            {/* <PageBanner pageTitle="Refund Policy" /> */}

            <div>
                <img src="https://ik.imagekit.io/ionicfirebaseapp/getwidget/gatsby-marketplace/Refund_policy_oJ-r6JXhz.png?updatedAt=1640669735319" className="img-fluid banner-img"/>
              
                </div>
            <div className="about-area ptb-80 pt-55">
                <div className="container-fluid padd-2">
                <div className="section-title">
                <h2>Refund Policy</h2>
                                    <div className="bar"></div>
                                </div>
                    <div className="row align-items-center">
                       
                        <div className="col-lg-12 col-md-12">
                            <div className="about-content">
                              
                                <ReactMarkdown children={data.strapiRefundPolicy.description} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
          

            {/* <Team /> */}

            {/* <Partner /> */}

            {/* <FunFactsArea /> */}

            <Footer />
        </Layout>
    )}></StaticQuery>
)

export default About1;
